import { useInView } from "react-intersection-observer";

const About = () => {
    const {ref, inView} = useInView({
        triggerOnce: true,
        rootMargin: '-100px 0px',
    })
  return (
    <div className={`about-wrapper ${!!inView && 'in-view'}`} ref={ref} id="about">
        <div className="about-container container">
            <div className="label">ABOUT</div>
            <div className="description">
            I'm a Software Engineer based in Montevideo, Uruguay, passionate about creating efficient, user-centered digital solutions. 
            I thrive in collaborative environments where technical skills and strategic thinking solve complex challenges, contributing to a positive and productive work environment.
            </div>
            <div className="chips">
                <div className="chip">
                    <div className="number"><span>+ </span>20</div>
                    <div className="label">total projects</div>
                </div>
                <div className="chip">
                    <div className="number"><span>+ </span>3</div>
                    <div className="label">Years of experience</div>
                </div>
            </div>
        </div>
    </div>
  );
};

export default About;