import { useEffect } from 'react';
import ReactGA from 'react-ga4';

import About from './components/About';
import CtaBanner from './components/CtaBanner';
import Footer from './components/Footer';
import Hero from './components/Hero';
import WorkExperience from './components/WorkExperience';

import './App.scss';

const GA_TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;

function App() {
  useEffect(() => {
    if (GA_TRACKING_ID) {
      ReactGA.initialize(GA_TRACKING_ID);
      ReactGA.send({ hitType: "pageview", page: 'home' });
    } else {
      console.warn('Missing Google Analytics Tracking ID');
    }
  }, []);
  
  return (
    <div className="App">
      <Hero />
      <WorkExperience />
      <About />
      <CtaBanner />
      <Footer />
    </div>
  );
}

export default App;
