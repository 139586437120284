import JobCard from "./JobCard";

const experiences = [
    {
        timeframe: { start: "Jul 2024", end: "Active" },
        role: "Software Engineer",
        company: "EverPass Media",
        description: "Enhance and integrate advanced features to deliver impactful live sports streaming experiences following Everpass Media’s acquisition of UPshow. Continue to contribute as the primary developer within my team, responsible for delivering key features, while also being the primary Salesforce engineering reference.",
    },  
    {
        timeframe: { start: "Apr 2022", end: "Jul 2024" },
        role: "Software Engineer",
        company: "UPshow",
        description: "Built and enhanced interactive on-screen content for businesses of all sizes using a diverse tech stack, including React, Express, and GraphQL. Was responsible for the development and execution of sprint deliveries as the primary developer, while serving as a Salesforce engineering reference.",
    },  
    {
        timeframe: { start: "Aug 2021", end: "Mar 2022" },
        role: "Software Developer",
        company: "Effectus Software",
        description: "Developed web and mobile applications using React and React Native as part of the front-end team. Contributed to a wide range of projects, including social media platforms, real estate management, and NFT based ticketing services.",
    },
    {
        timeframe: { start: "Feb 2021", end: "Jul 2021" },
        role: "Software Developer",
        company: "Arcadian",
        description: "Developed and maintained full-stack applications using React and Node/Express, leading the creation of a car rental management system.",
    },
];

const WorkExperience = () => {
  return (
    <div className="work-exp-wrapper" id="work">
      <div className="work-exp-bg">
        <div className="list container">
          {experiences.map( exp => {
            return <JobCard key={exp.company} {...exp}/>
          })}
        </div>
      </div>
    </div>
  );
};

export default WorkExperience;